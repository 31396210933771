import _is from "@sindresorhus/is";
var exports = {};
const is = _is;

exports = function deepFreeze(object) {
  for (const [key, value] of Object.entries(object)) {
    if (is.plainObject(value) || is.array(value)) {
      deepFreeze(object[key]);
    }
  }

  return Object.freeze(object);
};

export default exports;