import _url from "url";
import _http from "http";
import _pCancelable from "p-cancelable";
import _is from "@sindresorhus/is";
var exports = {};
const urlLib = _url;
const http = _http;
const PCancelable = _pCancelable;
const is = _is;

class GotError extends Error {
  constructor(message, error, options) {
    super(message);
    Error.captureStackTrace(this, this.constructor);
    this.name = "GotError";

    if (!is.undefined(error.code)) {
      this.code = error.code;
    }

    Object.assign(this, {
      host: options.host,
      hostname: options.hostname,
      method: options.method,
      path: options.path,
      socketPath: options.socketPath,
      protocol: options.protocol,
      url: options.href,
      gotOptions: options
    });
  }

}

exports.GotError = GotError;
exports.CacheError = class extends GotError {
  constructor(error, options) {
    super(error.message, error, options);
    this.name = "CacheError";
  }

};
exports.RequestError = class extends GotError {
  constructor(error, options) {
    super(error.message, error, options);
    this.name = "RequestError";
  }

};
exports.ReadError = class extends GotError {
  constructor(error, options) {
    super(error.message, error, options);
    this.name = "ReadError";
  }

};
exports.ParseError = class extends GotError {
  constructor(error, statusCode, options, data) {
    super(`${error.message} in "${urlLib.format(options)}": \n${data.slice(0, 77)}...`, error, options);
    this.name = "ParseError";
    this.statusCode = statusCode;
    this.statusMessage = http.STATUS_CODES[this.statusCode];
  }

};
exports.HTTPError = class extends GotError {
  constructor(response, options) {
    const {
      statusCode
    } = response;
    let {
      statusMessage
    } = response;

    if (statusMessage) {
      statusMessage = statusMessage.replace(/\r?\n/g, " ").trim();
    } else {
      statusMessage = http.STATUS_CODES[statusCode];
    }

    super(`Response code ${statusCode} (${statusMessage})`, {}, options);
    this.name = "HTTPError";
    this.statusCode = statusCode;
    this.statusMessage = statusMessage;
    this.headers = response.headers;
    this.body = response.body;
  }

};
exports.MaxRedirectsError = class extends GotError {
  constructor(statusCode, redirectUrls, options) {
    super("Redirected 10 times. Aborting.", {}, options);
    this.name = "MaxRedirectsError";
    this.statusCode = statusCode;
    this.statusMessage = http.STATUS_CODES[this.statusCode];
    this.redirectUrls = redirectUrls;
  }

};
exports.UnsupportedProtocolError = class extends GotError {
  constructor(options) {
    super(`Unsupported protocol "${options.protocol}"`, {}, options);
    this.name = "UnsupportedProtocolError";
  }

};
exports.TimeoutError = class extends GotError {
  constructor(error, options) {
    super(error.message, {
      code: "ETIMEDOUT"
    }, options);
    this.name = "TimeoutError";
    this.event = error.event;
  }

};
exports.CancelError = PCancelable.CancelError;
export default exports;