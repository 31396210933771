import _fs from "fs";
import _util from "util";
import _is from "@sindresorhus/is";
import _isFormData from "./is-form-data";
import _buffer from "buffer";
var exports = {};
var Buffer = _buffer.Buffer;
const fs = _fs;
const util = _util;
const is = _is;
const isFormData = _isFormData;

exports = async options => {
  const {
    body
  } = options;

  if (options.headers["content-length"]) {
    return Number(options.headers["content-length"]);
  }

  if (!body && !options.stream) {
    return 0;
  }

  if (is.string(body)) {
    return Buffer.byteLength(body);
  }

  if (isFormData(body)) {
    return util.promisify(body.getLength.bind(body))();
  }

  if (body instanceof fs.ReadStream) {
    const {
      size
    } = await util.promisify(fs.stat)(body.path);
    return size;
  }

  return null;
};

export default exports;