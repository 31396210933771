import _package from "../package.json";
import _create from "./create";
var exports = {};
const pkg = _package;
const create = _create;
const defaults = {
  options: {
    retry: {
      retries: 2,
      methods: ["GET", "PUT", "HEAD", "DELETE", "OPTIONS", "TRACE"],
      statusCodes: [408, 413, 429, 500, 502, 503, 504],
      errorCodes: ["ETIMEDOUT", "ECONNRESET", "EADDRINUSE", "ECONNREFUSED", "EPIPE", "ENOTFOUND", "ENETUNREACH", "EAI_AGAIN"]
    },
    headers: {
      "user-agent": `${pkg.name}/${pkg.version} (https://github.com/sindresorhus/got)`
    },
    hooks: {
      beforeRequest: [],
      beforeRedirect: [],
      beforeRetry: [],
      afterResponse: []
    },
    decompress: true,
    throwHttpErrors: true,
    followRedirect: true,
    stream: false,
    form: false,
    json: false,
    cache: false,
    useElectronNet: false
  },
  mutableDefaults: false
};
const got = create(defaults);
exports = got;
export default exports;