import _events from "events";
import _getStream from "get-stream";
import _is from "@sindresorhus/is";
import _pCancelable from "p-cancelable";
import _requestAsEventEmitter from "./request-as-event-emitter";
import _errors from "./errors";
import _merge from "./merge";
import _normalizeArguments from "./normalize-arguments";
var exports = {};
const EventEmitter = _events;
const getStream = _getStream;
const is = _is;
const PCancelable = _pCancelable;
const requestAsEventEmitter = _requestAsEventEmitter;
const {
  HTTPError,
  ParseError,
  ReadError
} = _errors;
const {
  options: mergeOptions
} = _merge;
const {
  reNormalize
} = _normalizeArguments;

const asPromise = options => {
  const proxy = new EventEmitter();
  const promise = new PCancelable((resolve, reject, onCancel) => {
    const emitter = requestAsEventEmitter(options);
    onCancel(emitter.abort);
    emitter.on("response", async response => {
      proxy.emit("response", response);
      const stream = is.null(options.encoding) ? getStream.buffer(response) : getStream(response, options);
      let data;

      try {
        data = await stream;
      } catch (error) {
        reject(new ReadError(error, options));
        return;
      }

      const limitStatusCode = options.followRedirect ? 299 : 399;
      response.body = data;

      try {
        for (const [index, hook] of Object.entries(options.hooks.afterResponse)) {
          // eslint-disable-next-line no-await-in-loop
          response = await hook(response, updatedOptions => {
            updatedOptions = reNormalize(mergeOptions(options, { ...updatedOptions,
              retry: 0,
              throwHttpErrors: false
            })); // Remove any further hooks for that request, because we we'll call them anyway.
            // The loop continues. We don't want duplicates (asPromise recursion).

            updatedOptions.hooks.afterResponse = options.hooks.afterResponse.slice(0, index);
            return asPromise(updatedOptions);
          });
        }
      } catch (error) {
        reject(error);
        return;
      }

      const {
        statusCode
      } = response;

      if (options.json && response.body) {
        try {
          response.body = JSON.parse(response.body);
        } catch (error) {
          if (statusCode >= 200 && statusCode < 300) {
            const parseError = new ParseError(error, statusCode, options, data);
            Object.defineProperty(parseError, "response", {
              value: response
            });
            reject(parseError);
            return;
          }
        }
      }

      if (statusCode !== 304 && (statusCode < 200 || statusCode > limitStatusCode)) {
        const error = new HTTPError(response, options);
        Object.defineProperty(error, "response", {
          value: response
        });

        if (emitter.retry(error) === false) {
          if (options.throwHttpErrors) {
            reject(error);
            return;
          }

          resolve(response);
        }

        return;
      }

      resolve(response);
    });
    emitter.once("error", reject);
    ["request", "redirect", "uploadProgress", "downloadProgress"].forEach(event => emitter.on(event, (...args) => proxy.emit(event, ...args)));
  });

  promise.on = (name, fn) => {
    proxy.on(name, fn);
    return promise;
  };

  return promise;
};

exports = asPromise;
export default exports;