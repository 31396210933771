import _decompressResponse from "decompress-response";
import _is from "@sindresorhus/is";
import _mimicResponse from "mimic-response";
import _progress from "./progress";
var exports = {};
const decompressResponse = _decompressResponse;
const is = _is;
const mimicResponse = _mimicResponse;
const progress = _progress;

exports = (response, options, emitter) => {
  const downloadBodySize = Number(response.headers["content-length"]) || null;
  const progressStream = progress.download(response, emitter, downloadBodySize);
  mimicResponse(response, progressStream);
  const newResponse = options.decompress === true && is.function(decompressResponse) && options.method !== "HEAD" ? decompressResponse(progressStream) : progressStream;

  if (!options.decompress && ["gzip", "deflate"].includes(response.headers["content-encoding"])) {
    options.encoding = null;
  }

  emitter.emit("response", newResponse);
  emitter.emit("downloadProgress", {
    percent: 0,
    transferred: 0,
    total: downloadBodySize
  });
  response.pipe(progressStream);
};

export default exports;